:root {
  --green-dark: #063706;
  --green-medium-dark: #075807;
  --green-medium: #097609;
  --green-medium-light: #70af1a;
  --green-light: #e6f5e6;
  --blue: #004589;
  --typography-light: #fff;
  --typography-dark: #000;
  --box-shadow: 0 8px 16px 1px rgba(0, 0, 0, 0.25);
  --box-shadow-focus: 0 12px 24px 2px rgba(0, 0, 0, 0.35);
  --spacing-05: 4px;
  --spacing-1: 8px;
  --spacing-2: 16px;
  --spacing-3: 24px;
  --spacing-4: 32px;
  --spacing-5: 40px;
  --spacing-6: 48px;
  --spacing-7: 56px;
  --spacing-8: 64px;
  --border-radius: 3px;
}

@-webkit-keyframes reveal {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }

  10% {
    opacity: 0;
    transform: scale(0.6);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }

  10% {
    opacity: 0;
    transform: scale(0.6);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(8vh);
  }

  10% {
    opacity: 0;
    transform: translateY(8vh);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(8vh);
  }

  10% {
    opacity: 0;
    transform: translateY(8vh);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

html,
body {
  margin: 0;
  color: #000000;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.heading-section {
  height: 60vh;
  min-height: 400px;
  border-radius: 0;
  background-image: url(../assets/images/scene-background.svg);
  background-color: var(--green-light);
  background-repeat: no-repeat;
  background-position: center bottom;
  border: none;
  font-family: 'Staatliches', Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading-section .heading-container {
  display: flex;
  align-items: center;
  letter-spacing: 0.6px;
}

.heading-section .heading-sign {
  height: 28vw;
  max-height: 175px;
  width: 28vw;
  max-width: 175px;
  background-color: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
  font-size: 180px;
  font-size: min(29vw, 180px);
  box-shadow: var(--box-shadow);
  color: var(--typography-light);
  margin: 14px 40px 0 0;
  margin: min(2vw, 14px) min(7vw, 40px) 0 0;
  font-weight: 600;
}

.heading-section .header {
  font-size: 100px;
  font-size: min(16.5vw, 100px);
  color: var(--green-dark);
  margin: 0 0 -10px;
  font-weight: 400;
}

.heading-section .subheader {
  font-size: 38px;
  font-size: min(6.27vw, 38px);
  color: var(--green-medium-dark);
  margin: 0;
  font-weight: 400;
}

.heading-section .name {
  font-size: 46px;
  font-size: min(7.6vw, 46px);
  color: var(--green-medium);
  margin: 16px 0 100px;
  margin: min(16px, 3vw) 0 100px;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.heading-section .heading-container,
.heading-section .name {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: reveal;
  animation-name: reveal;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
}

.panel-container {
  display: flex;
  flex-direction: column-reverse;
}

.panel {
  width: calc(100% - var(--spacing-8));
  padding: calc(var(--spacing-4) + 24px) var(--spacing-4) var(--spacing-4);
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  margin-bottom: -24px;
  box-shadow: var(--box-shadow);
  display: flex;
  justify-content: center;
}

.panel .panel-content {
  width: 100%;
  max-width: 1000px;
  margin: 4vw 0;
}

.panel h3 {
  font-family: 'Staatliches', 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 100px;
  font-size: min(11.5vw, 100px);
  letter-spacing: 0.4px;
  font-weight: 400;
  margin: 0;
  line-height: 100%;
}

.panel h4 {
  font-family: 'Staatliches', 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 60px;
  font-size: min(6.9vw, 60px);
  letter-spacing: 0.2px;
  font-weight: 400;
  margin: 0;
  line-height: 100%;
}

.panel p {
  line-height: 150%;
}

.withOne::after {
  content: '1';
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 96%;
  font-weight: 600;
  margin-left: max(-12px, -2vw);
}

.panel-dark {
  min-height: min(
    calc(35vh - var(--spacing-8)),
    calc(100vh - 400px - var(--spacing-8))
  );
  background-color: var(--green-dark);
  flex-direction: column;
  align-items: center;
  transform: translateZ(3px);
  z-index: 19;
  padding: var(--spacing-4);
  color: var(--typography-light);
}

.panel-dark a {
  text-decoration: none;
  font-family: 'Staatliches', Arial, Helvetica, sans-serif;
  font-size: min(6vw, 24px);
  letter-spacing: 0.6px;
  padding: var(--spacing-05) var(--spacing-2);
  color: inherit;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-name: slide-up;
  animation-name: slide-up;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  border-radius: var(--border-radius);
  transition: background-color 0.25s ease;
}

.panel-dark a:hover {
  background-color: var(--green-medium-dark);
}

.panel-dark a:active {
  background-color: var(--green-medium);
}

.panel-dark p {
  margin: 0;
}

.panel-medium-dark {
  background-color: var(--green-medium-dark);
  transform: translateZ(2px);
  z-index: 18;
  color: var(--typography-light);
}

.card-container {
  margin-top: var(--spacing-1);
  display: flex;
  flex-direction: column;
  width: calc(100% + 24px);
  transform: translateX(-12px);
}

.card {
  padding: var(--spacing-2) var(--spacing-2) 0;
  border-radius: var(--border-radius);
  margin: var(--spacing-2) 0;
}

.card:nth-child(2n + 1) {
  background-color: var(--green-dark);
  box-shadow: var(--box-shadow);
}

.card a {
  color: var(--green-light);
}

.card-big-start {
  grid-column: 1 / -2;
}

.card-big-end {
  grid-column: 2 / -1;
}

.card-full-width {
  grid-column: 1 / -1;
}

.panel-medium-dark h4 {
  margin: 0 0 16px;
}

.table {
  overflow-x: auto;
  padding: var(--spacing-1);
  border: 1px solid var(--typography-light);
  border-radius: var(--border-radius);
}

.table::-webkit-scrollbar {
  -webkit-appearance: none;
}

.table::-webkit-scrollbar:horizontal {
  height: 11px;
}

.table::-webkit-scrollbar-thumb {
  border-radius: var(--border-radius);
  border: 1px solid var(--typography-light);
  border-top: 2px solid var(--typography-light);
  background-color: var(--green-dark);
}
.table::-webkit-scrollbar-track {
  background-color: var(--typography-light);
}

.panel-medium-dark .table-3 {
  display: grid;
  grid-template-columns: 1fr 4fr 3fr;
  grid-auto-columns: auto;
  grid-gap: var(--spacing-1);
  min-width: 350px;
  max-width: 500px;
}

.panel-medium-dark .table-2 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-auto-columns: auto;
  grid-gap: var(--spacing-1);
  min-width: 350px;
  max-width: 600px;
}

.table-3 p,
.table-2 p {
  margin: 0;
}

.panel-medium {
  background-color: var(--green-medium);
  transform: translateZ(1px);
  z-index: 17;
  color: var(--typography-light);
}

.panel-medium .panel-content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 0 var(--spacing-2);
}

.panel-medium .about-heading {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.panel-medium .about-content {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.panel-medium .about-image {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  align-self: flex-end;
  width: 100%;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.panel-medium-light {
  background-color: var(--green-medium-light);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0;
  color: var(--typography-dark);
}

.impressum-content {
  display: grid;
  grid-template-columns: 50% 50%;
}

.impressum-card {
  margin: var(--spacing-2) 0 0;
}

.impressum-address,
.impressum-contact {
  margin: 16px 0;
}

.impressum-address p,
.impressum-contact p {
  margin: 0;
}

.impressum-contact a {
  color: var(--green-dark);
}

@media only screen and (max-width: 869px) {
  .panel h3 {
    font-size: 11.5vw;
    font-size: min(11.5vw, 100px);
  }

  .panel h4 {
    font-size: 6.9vw;
    font-size: min(6.9vw, 60px);
  }
}

@media only screen and (max-width: 699px) {
  .panel-medium .panel-content {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
  }

  .impressum-content {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 619px) {
  .panel-medium .panel-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-gap: var(--spacing-2) 0;
  }

  .panel-medium .about-heading {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .panel-medium .about-content {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .panel-medium .about-image {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    width: 100%;
  }
}

@media only screen and (max-width: 606px) {
  .heading-section .heading-sign {
    font-size: 29vw;
    font-size: min(29vw, 180px);
  }

  .heading-section .header {
    font-size: 16.5vw;
    font-size: min(16.5vw, 100px);
  }

  .heading-section .subheader {
    font-size: 6.27vw;
    font-size: min(6.27vw, 38px);
  }

  .heading-section .name {
    font-size: 7.6vw;
    font-size: min(7.6vw, 46px);
  }

  .heading-section .heading-sign {
    margin: 2vw 40px 0 0;
    margin: min(2vw, 14px) min(7vw, 40px) 0 0;
  }
}

@media only screen and (max-width: 571px) {
  .heading-section .heading-sign {
    margin: 2vw 7vw 0 0;
    margin: min(2vw, 14px) min(7vw, 40px) 0 0;
  }
}

@media only screen and (max-width: 533px) {
  .heading-section .name {
    margin: 3vw 0 100px;
    margin: min(16px, 3vw) 0 100px;
  }
}

@media only screen and (min-width: 799px) {
  .card-container {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    grid-auto-rows: auto;
    grid-auto-flow: row;
    grid-gap: var(--spacing-2);
    margin-top: var(--spacing-2);
    width: 100%;
    transform: none;
  }

  .card {
    margin: 0;
    background-color: var(--green-dark);
    box-shadow: var(--box-shadow);
    transition: box-shadow 0.25s ease;
  }

  .card:hover {
    box-shadow: var(--box-shadow-focus);
  }
}
